'use client';

import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';
import Image from 'next/image';
import {
  LicenseItechFilled,
  LicenseSecurityFilled,
  LicenseThunderFilled,
  ProjectAFilled,
  ProjectBFilled,
  ResponsiblyGameCareFilled,
  SocialFacebookFilled,
  SocialTelegramFilled,
  SocialYoutubeFilled,
} from '@packages/icons-react';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const aboutData = [
  { icon: <LicenseSecurityFilled />, type: 'license' },
  { icon: <LicenseThunderFilled />, type: 'license' },
  { icon: <LicenseItechFilled />, type: 'license' },
  { icon: <ProjectAFilled />, type: 'project' },
  { icon: <ProjectBFilled />, type: 'project' },
  { icon: <ResponsiblyGameCareFilled />, type: 'responsibly' },
  { icon: <SocialFacebookFilled />, type: 'social' },
  { icon: <SocialTelegramFilled />, type: 'social' },
  { icon: <SocialYoutubeFilled />, type: 'social' },
];

const Footer = (): JSX.Element => {
  const { t } = useTranslation('home');
  const aboutGroup = groupBy(aboutData, 'type');

  return (
    <footer className="w-full p-6">
      <div className="flex flex-col gap-4 sm:mb-20 lg:hidden">
        <div>
          <h3 className="text-sm font-bold md:text-2xl">
            {t('footer.brand-ambassador')}
          </h3>
          <p className="text-sm md:text-xs">
            Ronaldinho <br /> Year 2024-2025
          </p>
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <Partners />
        </div>
        <div className="mt-10 grid grid-cols-2 gap-6">
          {Object.keys(aboutGroup).map((item) => (
            <div className="flex flex-col" key={item}>
              <h3 className="mb-2 text-xs uppercase">{item}</h3>
              <div className="flex items-center gap-x-4">
                {aboutGroup[item].map((about, i) => (
                  <div className="text-light text-[30px]" key={item + i}>
                    {about.icon}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="mt-8 space-y-2">
          <h4 className="text-center text-sm font-bold uppercase">
            NEW88 - HIGH QUALITY ONLINE BETTING PLATFORM
          </h4>
          <p className="text-justify text-xs">
            NEW88 is a bookmaker with a legal online betting license issued by
            the Isle of Man and the Cagayan and Freeport Economic Zone. With
            extensive experience and a reputation for serving more than 10
            million players, NEW88 has been affirming its position in the online
            gaming market. As an online gaming company under the OKVIP Alliance
            Headquarters based in London, UK. Possessing a large team of
            professional talents providing high quality service products. NEW88
            guarantees not to disclose customer personal information to any
            third party, using the highest level of data encryption standards.
            All personal information is passed through the security system -
            Secure Socket (SS 128-bit encryption standard), and is protected in
            a secure management environment that ensures it cannot be accessed
            from public network areas. All incoming and outgoing data is
            restricted, strictly monitored and tightly managed to provide
            players with an absolutely safe user experience.
          </p>
        </div>
      </div>

      <div className="hidden lg:block">
        <div className="flex w-full grid-cols-3 justify-center gap-x-10 px-10 xl:px-[100px]">
          <div className="relative">
            <h3 className="text-2xl font-bold">{t('footer.partner')}</h3>
            <span className="text-xs">Year 2024-2025</span>
            <div className="flex gap-x-10">
              <Partners />
            </div>
          </div>
          <div className="relative w-[250px]">
            <h3 className="text-2xl font-bold">
              {t('footer.brand-ambassador')}
            </h3>
            <div className="relative mt-8 h-[54px] w-[136px]">
              <Image
                alt="footer-r10"
                draggable={false}
                fill
                src={`${S3_BUCKET_IMAGES}/footer-r10.webp`}
              />
              <div className="absolute -right-full top-0 h-[130px] w-[120px] -translate-x-5 -translate-y-5">
                <Image
                  alt="footer-r10-model"
                  draggable={false}
                  fill
                  src={`${S3_BUCKET_IMAGES}/footer-r10-model.webp`}
                />
              </div>
            </div>

            <p className="mt-5 text-xs">
              Ronaldinho <br /> Year 2024-2025
            </p>
          </div>
        </div>
        <div className="mt-16 flex flex-wrap justify-center gap-8 px-10 xl:px-[100px]">
          {aboutData.map((item, i) => (
            <div className="text-light text-[60px]" key={i}>
              {item.icon}
            </div>
          ))}
        </div>
        {/*<div className="mt-5 flex flex-wrap justify-center px-10 xl:px-[100px]">*/}
        {/*  {[*/}
        {/*    'Terms and Conditions',*/}
        {/*    'About NEW88i',*/}
        {/*    'Responsible Gaming',*/}
        {/*    'Disclaimer',*/}
        {/*    'Privacy at  NEW88i',*/}
        {/*    'Frequently Asked Questions',*/}
        {/*  ].map((item, i) => (*/}
        {/*    <div*/}
        {/*      className="mt-5 border-r border-white px-4 text-white last:border-none"*/}
        {/*      key={i}*/}
        {/*    >*/}
        {/*      {item}*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
        <div className="mt-6 space-y-4 text-center text-xs">
          <p>
            Copyright © New88i Bet is the ideal place for players to
            participate in entertainment, Browse through history. All rights
            reserved.
          </p>
          <p>
            Attractive game products, join now to experience the best service.
          </p>
        </div>
      </div>
    </footer>
  );
};

const Partners = (): JSX.Element => {
  const partners = [
    { name: 'argentina', text: 'Argentina AFA & OKVIP' },
    { name: 'villarreal', text: 'Villarreal CF & OKVIP' },
  ];

  return (
    <>
      {partners.map((item, i) => (
        <div
          className="flex min-w-full flex-row items-center md:min-w-[285px] md:flex-col"
          key={i}
        >
          <div className="order-2 my-2 flex h-9 w-full justify-end gap-x-4 md:order-1 md:my-4 md:h-[70px] md:justify-start ">
            <div className="relative h-full w-full max-w-[27px] md:max-w-[55px]">
              <Image
                alt={item.name}
                draggable={false}
                fill
                src={`${S3_BUCKET_IMAGES}/footer-${item.name}.webp`}
              />
            </div>
            <div className="h-full w-[1px] bg-white" />
            <div className="relative mr-4 h-full w-full max-w-[100px] md:max-w-[196px]">
              <Image
                alt="ok"
                draggable={false}
                fill
                src={`${S3_BUCKET_IMAGES}/footer-ok.webp`}
              />
            </div>
          </div>
          <span className="order-1 w-full text-sm font-bold md:order-2 md:text-xs md:font-normal">
            {item.text}
          </span>
        </div>
      ))}
    </>
  );
};

export default Footer;
